import React, { Component } from 'react';

class LatestNewsSection extends Component{
	render(){
		
		return(
			<>
				
				<section className="section-sp1 service-wraper2">
					<div className="container">
						<div className="row">
							<div className="col-xl-3 col-sm-6 mb-30">
								<div className="feature-container feature-bx3">
									<h2 className="counter text-secondary">98+</h2>
									<h5 className="ttr-title">Channel<br />Partners</h5>
									{/* <p>Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.</p> */}
								</div>
							</div>
							<div className="col-xl-3 col-sm-6 mb-30">
								<div className="feature-container feature-bx3">
									<h2 className="counter text-secondary">33+</h2>
									<h5 className="ttr-title">Project<br />Completed</h5>
									{/* <p>Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.</p> */}
								</div>
							</div>
							<div className="col-xl-3 col-sm-6 mb-30">
								<div className="feature-container feature-bx3">
									<h2 className="counter text-secondary">250+</h2>
									<h5 className="ttr-title">Satisfied<br />Customers</h5>
									{/* <p>Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.</p> */}
								</div>
							</div>
							<div className="col-xl-3 col-sm-6 mb-30">
								<div className="feature-container feature-bx3">
									<h2 className="counter text-secondary">20+</h2>
									<h5 className="ttr-title">Years<br />Experience</h5>
									
									{/* <p>Etiam ante ante, molestie vitae cursus ac, pharetra euismod libero.</p> */}
								</div>
							</div>
						</div>
					</div>
				</section>
				
			</>
		);
	}
}

export default LatestNewsSection;