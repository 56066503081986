import React, { Component } from "react";
import { Link } from "react-router-dom";


// Layout
import Header from "../../layout/header2";
import Footer from "../../layout/footer";

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/services/Medical Tourism In India.png";
import pdf from "../../../images/icon/pdf.png";
import doc from "../../../images/icon/doc.png";

class Stwo extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div className="banner-wraper">
            <div
              className="page-banner"
              style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>Medical Tourism In India</h1>
                  <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="feather feather-home"
                          >
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                          </svg>{" "}
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Medical Tourism In India
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
              <img className="pt-img2 animate2" src={circleDots} alt="" />
              <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
            </div>
          </div>

          <section className="section-area section-sp1">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mb-30">
                  <div className="ttr-media mb-30">
                    <img src={servicesPic1} className="rounded" alt="" />
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <h2 className="title mb-15">
                      Medical Tourism In India
                      </h2>
                      
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      India is renowned around the world for its superiority in the medical field. In recent years, medical tourism in India has grown dramatically and produced outstanding outcomes. India's medical tourism industry is expanding quickly because it provides high-quality treatment at affordable prices. The medical treatments offered in India have helped countless individuals from various remote parts of the world. Oddly enough, the count is still going!
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      Ansh Healthcare Solution is a one-stop platform that provides a wide range of medical treatments in India as part of its noble endeavour to serve humanity. Our unrivalled knowledge, top-notch physicians, extensive experience, and premier hospitals enable us to dominate the Indian medical tourism market. The patient can receive affordable treatment packages in India from Ansh Healthcare Solution. In addition, it is made sure that the patient and any accompanying person have a comfortable stay in India, which aids in their quick recovery.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      Ansh Healthcare Solution provides prospective medical tourists with a comprehensive solution. A patient from anywhere in the globe may receive hassle-free treatment in India combined with travel, lodging, and local sightseeing thanks to this one-stop solution.
                      </p>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <h4 className="title mb-10">What We Provide</h4>
                    </div>
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{ textAlign: "justify" ,fontWeight: 'bold', color: 'black'}}>
                    Visa Support
                      </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      Ansh Healthcare Solutions assists with visa arrangements and complete medical travel arrangements. In order to stay current on the policies of the External Affairs Ministry and the High Commission/Embassy of India on medical travel and assistance, we have devoted teams. For patients who are trying to access medical care abroad, we will provide visa and travel help. We tend to focus on providing thorough travel help for medical reasons, lodging, and other necessary services as a reputable healthcare facilitator in India.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{ textAlign: "justify" ,fontWeight: 'bold', color: 'black'}}>
                    Air Tickets
                      </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      Ansh Healthcare Solution claims to have provided the most cheap tickets to all of its patients. Medical travel necessitates having an economical aeroplane ticket purchasing system. We assist medical patients in obtaining affordable tickets for all kinds of cars and forms of transportation, as well as lodging.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{ textAlign: "justify" ,fontWeight: 'bold', color: 'black'}}>
                    Transportation Service
                      </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      The related teams and department at Ansh Healthcare Solutions also provide taxi services for patients to be picked up and dropped off at airports and other locations. Patients from all around the world may easily travel thanks to our extensive travel help. IHS will be the best option if you're seeking for skilled medical travel help and medical tourism that can meet all of your demands in one place. Additionally, we provide our patients with complimentary airport pickup and delivery services.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{ textAlign: "justify" ,fontWeight: 'bold', color: 'black'}}>
                    Accommodation
                      </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      Additionally, patients may learn about several local lodging options from Ansh Healthcare Solutions. We must provide our patients with the appropriate information for their homes. We know people who run hotels and serviced residences. We are aware that finding housing for patients who may need to remain for an extended period of time or receive their medical care in multiple locations close to hospitals can be challenging. We will assist in scheduling an appointment and providing suitable lodging options, regardless of where it is in the operational cities. With AHS's extensive services, scheduling an appointment with a doctor in India will be simple.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{ textAlign: "justify" ,fontWeight: 'bold', color: 'black'}}>
                    Consultancy
                      </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      Ansh Healthcare Solutions, a medical consultant, operates on the main tenet of offering minimum and affordable healthcare in the nation. To ensure that the patient is protected from all sides, we cater to a variety of measurements. Ansh Healthcare Solutions provides visitors with medical consultation support so they may get medical treatment from a professional organisation experienced in all areas of post-marketing, clinical protection, including medical knowledge. When it comes to the advising area, we make every effort to provide the patient with accurate information about their problems and suitable hospitals and physicians for the finest care in India.
                      </p>
                    </div>
                    
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{ textAlign: "justify" ,fontWeight: 'bold', color: 'black'}}>
                    Quotation
                      </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      We serve as a one-stop shop for all required health standards. You can contact us and receive all the necessary quotes if you have any questions about quotations. After presenting their issues, patients may receive quotes fast, which ultimately enables them to grasp the whole cost of the therapy and make an informed decision from among the many available healthcare options. We promise to provide you with the most cheap medical care possible in India to hasten your recovery. Our medical services are reasonably priced, and continuous communication will help you better understand the costs quoted by various hospitals and physicians.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{ textAlign: "justify" ,fontWeight: 'bold', color: 'black'}}>
                    Medical Appointment
                      </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      As it provides a centralised platform to preserve the victims' medical information and solutions, digitising medical practise enhances the performance and effectiveness of the specialists. The patient's therapy experiences may be accessed, and we can watch the patient's progress to improve treatment outcomes. All of these tasks are made simpler by the electronic health records.
                      </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      A doctor's visit is essential in a nation where notable doctors live in large cities and people travel from all over the world. We provide you with the likely times to and schedule your appointments with the appropriate doctors in order to ensure that you have a hassle-free consultation. The availability of our online doctor scheduling service attracts thousands of people annually.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <aside className="sticky-top pb-1">
                    <div className="widget">
                      <ul className="service-menu">
                        <li>
                          <Link to="/healthcare_quality_and_accreditations">
                            <span>Healthcare Quality & Accreditations</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li className="active">
                          <Link to="/medical_tourism_in_india">
                            <span>Medical Tourism In India</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/hospital_planning">
                            <span>Hospital Planning</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/biomedical_equipment_planning_and_procurement">
                            <span>
                              Biomedical Equipment Planning and Procurement
                            </span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/human_resource_planning">
                            <span>Human Resource Planning</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/hospital_marketing">
                            <span>Hospital Marketing</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/corporate_and_tpa_business_tie_ups">
                            <span>Corporate and TPA Business Tie-Ups</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/insurance_services">
                            <span>Insurance Services</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/hospital_licenses">
                            <span>Hospital Licenses</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/management_consulting">
                            <span>Management Consulting</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="widget">
                      <div className="brochure-bx">
                        <h5 className="title-head">Download</h5>
                        <Link to="#" className="download-link">
                          <img src={pdf} alt="" />
                          <h5 className="title">Download our Brochures</h5>
                          <span>Download</span>
                        </Link>
                        <Link to="#" class="download-link">
                          <img src={doc} alt="" />
                          <h5 className="title">Our Company Details</h5>
                          <span>Download</span>
                        </Link>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default Stwo;
