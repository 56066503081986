import React, { useState } from "react";
import axios from "axios";

// Images
import lineBg from "../../images/appointment/line-bg.png";
import appMobile from "../../images/appointment/mobile.png";
import appWomen from "../../images/appointment/women.png";
import appMapPin from "../../images/appointment/map-pin.png";
import appSetting from "../../images/appointment/setting.png";
import appCheck from "../../images/appointment/check.png";
import appChat from "../../images/appointment/chat.png";
import ptImg1 from "../../images/shap/trangle-orange.png";
import ptImg2 from "../../images/shap/wave-orange.png";
import ptImg3 from "../../images/shap/wave-blue.png";
import ptImg4 from "../../images/shap/circle-orange.png";

const initalState = {
  name: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
};

const AboutSection = () => {
  const [state, setState] = useState(initalState);

  const { name, email, phone, subject, message } = state;

  const handleSubmit = (e) => {
    console.log("click");
    e.preventDefault();
    if (!name || !email || !phone || !subject || !message) {
      console.log("Please provide value");
    } else {
      axios
        .post("https://gurusoftware.in/napi/add-mail.php", {
          name,
          email,
          phone,
          subject,
          message,
        })
        .then(function (response) {
          setState({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
          console.log(response.data.message);
        })
        .catch((err) => console.log(err.response.data));
    }
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  return (
    <>
      <section className="section-area account-wraper1">
        <div className="container-fluid">
          <div
            className="appointment-inner section-sp2"
            style={{
              backgroundImage: "url(" + lineBg + ")",
              backgroundRepeat: " no-repeat",
              backgroundPosition: "20px 140px",
            }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-5 col-lg-6 col-md-6">
                  <div className="appointment-form form-wraper">
                    <h3 className="title">Schedule Callback</h3>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
					  <input
                          name="name"
                          type="text"
                          value={name}
                          onChange={changeHandler}
                          required
                          className="form-control"
                          placeholder="Your Name"
                        />
                      </div>
                      <div className="form-group">
					  <input
                          name="email"
                          id="email"
                          type="email"
                          value={email}
                          onChange={changeHandler}
                          required
                          className="form-control"
                          placeholder="Email"
                        />
                      </div>
                      <div className="form-group">
					  <input
                          name="phone"
                          type="text"
                          value={phone}
                          onChange={changeHandler}
                          required
                          className="form-control"
                          placeholder="Phone Numbers"
                        />
                      </div>
					  <div className="form-group">
					  <input
                          name="subject"
                          type="text"
                          value={subject}
                          onChange={changeHandler}
                          required
                          className="form-control"
                          placeholder="Subject"
                        />
                      </div>
					  <div className="form-group">
					  <textarea
                          name="message"
                          value={message}
                          onChange={changeHandler}
                          required
                          className="form-control"
                          placeholder="Type Message"
                        ></textarea>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-secondary btn-lg"
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-6 col-md-6">
                  <div className="appointment-thumb">
                    <img src={appMobile} alt="" />
                    <div className="images-group">
                      <img className="img1" src={appWomen} alt="" />
                      <img className="img2" src={appMapPin} alt="" />
                      <img className="img3" src={appSetting} alt="" />
                      <img className="img4" src={appCheck} alt="" />
                      <img className="img5" src={appChat} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img className="pt-img1 animate1" src={ptImg1} alt="" />
            <img className="pt-img2 animate-wave" src={ptImg2} alt="" />
            <img className="pt-img3 animate-wave" src={ptImg3} alt="" />
            <img className="pt-img4 animate2" src={ptImg4} alt="" />
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutSection;
