import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../../layout/header2";
import Footer from "../../layout/footer";

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/services/Management Consulting.png";
import pdf from "../../../images/icon/pdf.png";
import doc from "../../../images/icon/doc.png";

class Sten extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div className="banner-wraper">
            <div
              className="page-banner"
              style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>Management Consulting</h1>
                  <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="feather feather-home"
                          >
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                          </svg>{" "}
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Management Consulting
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
              <img className="pt-img2 animate2" src={circleDots} alt="" />
              <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
            </div>
          </div>

          <section className="section-area section-sp1">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mb-30">
                  <div className="ttr-media mb-30">
                    <img src={servicesPic1} className="rounded" alt="" />
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <h2 className="title mb-15">Management Consulting</h2>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      Governmental rules are getting increasingly complicated. Compliance has never been more important or challenging. Margins are shrinking at the same time as there is a greater need to reduce expenses. All the while working toward a value-based paradigm to enhance the standard of treatment and results. To help you manage these increasing problems in a dynamic environment, Ansh Healthcare specialists supply you with a wide range of healthcare provider services. To assist you effectively guide your firm into the future, Ansh Healthcare specialists and our Center for Health Solutions will deliver you cutting-edge ideas and viewpoints supported by in-depth industry research.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    <h4 className="title mb-10">Offers to Healthcare Professionals</h4>
                      <div className="col-md-12 mb-30">
                        <ul className="list-check-squer mb-0">
                          <li>
                          Transformation Facilitated by Technology
                          </li>
                          <li>
                          Solutions for Health Care Regulation
                          </li>
                          <li>
                          Strategie and M&A
                          </li>
                          <li>
                          Talent Enhancement
                          </li>
                          <li>
                            Durable Margin
                          </li>
                          <li>
                          Care Based on Value
                          </li>
                          <li>
                          Healthcare Business and Ambulatory Services
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="head-text mb-30">
                    <p
                        className="mb-0"
                        style={{ fontWeight: "bold", color: "black" }}
                      >Gaining market share
                        </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      customers to patients. from quantity to value The playing field is moving, and those that embrace innovation today with conviction will be the ones who survive. Ansh Healthcare, the biggest healthcare consulting company, is participating in this transformation alongside you. Our goal is to make sure you triumph.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <aside className="sticky-top pb-1">
                    <div className="widget">
                      <ul className="service-menu">
                        <li>
                          <Link to="/healthcare_quality_and_accreditations">
                            <span>Healthcare Quality & Accreditations</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/medical_tourism_in_india">
                            <span>Medical Tourism In India</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/hospital_planning">
                            <span>Hospital Planning</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/biomedical_equipment_planning_and_procurement">
                            <span>
                              Biomedical Equipment Planning and Procurement
                            </span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/human_resource_planning">
                            <span>Human Resource Planning</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/hospital_marketing">
                            <span>Hospital Marketing</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/corporate_and_tpa_business_tie_ups">
                            <span>Corporate and TPA Business Tie-Ups</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/insurance_services">
                            <span>Insurance Services</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/hospital_licenses">
                            <span>Hospital Licenses</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li className="active">
                          <Link to="/management_consulting">
                            <span>Management Consulting</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="widget">
                      <div className="brochure-bx">
                        <h5 className="title-head">Download</h5>
                        <Link to="#" className="download-link">
                          <img src={pdf} alt="" />
                          <h5 className="title">Download our Brochures</h5>
                          <span>Download</span>
                        </Link>
                        <Link to="#" class="download-link">
                          <img src={doc} alt="" />
                          <h5 className="title">Our Company Details</h5>
                          <span>Download</span>
                        </Link>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default Sten;
