import React, { Component } from "react";
import { Link } from "react-router-dom";


// Layout
import Header from "../../layout/header2";
import Footer from "../../layout/footer";

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/services/Quality & Accreditations.png";
import pdf from "../../../images/icon/pdf.png";
import doc from "../../../images/icon/doc.png";

class Sone extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div className="banner-wraper">
            <div
              className="page-banner"
              style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>Healthcare Quality & Accreditations</h1>
                  <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="feather feather-home"
                          >
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                          </svg>{" "}
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Healthcare Quality & Accreditations
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
              <img className="pt-img2 animate2" src={circleDots} alt="" />
              <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
            </div>
          </div>

          <section className="section-area section-sp1">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mb-30">
                  <div className="ttr-media mb-30">
                    <img src={servicesPic1} className="rounded" alt="" />
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <h2 className="title mb-15">
                        Healthcare Quality & Accreditations
                      </h2>
                      <p>(Full & Entry Lever NABH)</p>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        The Full NABH, Entry Level NABH, NABL, and JCI
                        accreditations and certifications are regarded as
                        renowned acknowledgements of the healthcare quality
                        since they significantly raise the standard of care and
                        services offered by Large, Medium, and Small Healthcare
                        Organizations. Quality Standards serve as the foundation
                        for an unbiased assessment of the procedure that can
                        assist healthcare organisations in measuring,
                        evaluating, and enhancing performance. The majority of
                        these gains are only attainable with widespread
                        adjustments to organisational processes.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        The top healthcare quality consultancy in India is Ansh
                        Healthcare Solutions. In order to effectively implement
                        quality management systems like the JCI, Full NABH,
                        Entry Level NABH, NABL, and the ISO 9001, we have worked
                        with over 1000 hospitals and labs in the private and
                        public sectors since our founding in 2005. Our own staff
                        of healthcare quality experts is currently the largest
                        in the nation. We have maintained the same accreditation
                        for NABH and Entry Level NABH in India for 14 years
                        without an interruption, making us the only Quality
                        Council of India Accredited Consultancy. Additionally,
                        we received the highest rating for Quality Improvement
                        Projects from NHSRC, the technical arm of the Government
                        of India's National Rural Health Mission.
                      </p>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <h4 className="title mb-10">NABH</h4>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        The highest benchmark level for hospital quality in
                        India is now the National Accreditation Board for
                        Hospitals & Healthcare Providers (NABH) Standards. There
                        are primarily two versions of the Standards for
                        allopathic hospitals: the Full NABH, which is
                        appropriate for large specialty and tertiary care
                        hospitals, and the Entry Level NABH, which is primarily
                        intended for smaller hospitals that lack the
                        infrastructure and resources necessary to comply with
                        the Full NABH.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        Although the Full NABH was initially created by the
                        Quality Council of India on the basis of international
                        accreditation standards like the JCI, ACHS, and the
                        Canadian Hospital Accreditation Standards, it has since
                        undergone five significant revisions and is now regarded
                        as a sophisticated and extremely useful set of Standards
                        that is current and pertinent to the needs of India's
                        particular healthcare system. Additionally, ISQUa, the
                        International Society for Quality Assurance in
                        Healthcare, recognises it as a top-tier international
                        accreditation. Nearly 8 years later, the Entry Level
                        NABH was created to address the needs of smaller
                        hospitals that were having trouble adhering to the Full
                        NABH's more onerous criteria.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        For hospitals of all shapes and sizes, the Full NABH and
                        the Entry Level Quality Standards serve as functional
                        management systems that allow them to provide High
                        Quality Services. Thus, the Standards cover every aspect
                        of hospital operations and services, from patient
                        registration and initial evaluation to laboratory
                        services that will be provided both internally and
                        externally, display of patient rights & education,
                        policies & procedures on patient care, management of
                        medication, hospital infection control process that has
                        been implemented, facility management & safety, and
                        human resources management.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        Both the Full NABH Accreditation and the Entry Level
                        Certification benefit patients greatly since they lead
                        to excellent standards of service and patient safety.
                        The patients receive assistance from licenced medical
                        personnel. Patients' rights are upheld and safeguarded.
                        Patient satisfaction is monitored on a regular basis.
                        The hospital's workforce benefits from quality
                        accreditations and certifications as well since they
                        promote leadership, leadership development, and, most
                        importantly, clinical process ownership.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        Both Full NABH Accreditation and Entry Level NABH
                        Application in Hospitals Stimulate Continuous
                        Improvement, Enable Hospitals to Show Commitment to
                        Quality Care and Raise Community Confidence in the
                        Services Provided by the Hospitals. They offer the
                        chance to compare yourself to the finest.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        Implementing the Full NABH or the Entry Level NABH will
                        have significant positive effects on hospital
                        performance.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <div className="col-md-12 mb-30">
                        <ul className="list-check-squer mb-0">
                          <li> A reduction in hospital infections, medical mistakes, and accidents, which shortens unnecessary hospital stays</li>
                          <li>Cost-reduction through increased operational effectiveness</li>
                          <li>Increased equipment utilisation through less downtime and failures</li>
                          <li>More effective use of staff via training and more precise position and responsibility assignment with accreditation and privileges</li>
                          <li> Improving inventory management and optimising material use to prevent stockouts</li>
                        </ul>
                      </div>
                    </div>
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        Both the Full NABH and the Entry Level NABH are used by
                        administrators of insurance and other third parties as a
                        fair method of empanelment. The Indian Insurance
                        Companies have recently released a three tier package
                        that divides hospitals into three categories. A, B, and
                        C categories based on which tariffs for cashless
                        hospitalisation treatments will be set. Accreditation
                        provides access to reliable and certified information on
                        facilities, infrastructure, and level of care.
                        Naturally, the Hospital's A Category placement will be
                        aided by its Full NABH Accreditation.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <aside className="sticky-top pb-1">
                    <div className="widget">
                      <ul className="service-menu">
                        <li className="active">
                          <Link to="/healthcare_quality_and_accreditations">
                            <span>Healthcare Quality & Accreditations</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
						<li>
                          <Link to="/medical_tourism_in_india">
                            <span>Medical Tourism In India</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/hospital_planning">
                            <span>Hospital Planning</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/biomedical_equipment_planning_and_procurement">
                            <span>Biomedical Equipment Planning and Procurement</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/human_resource_planning">
                            <span>Human Resource Planning</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/hospital_marketing">
                            <span>Hospital Marketing</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/corporate_and_tpa_business_tie_ups">
                            <span>Corporate and TPA Business Tie-Ups</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
						<li>
                          <Link to="/insurance_services">
                            <span>Insurance Services</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/hospital_licenses">
                            <span>Hospital Licenses</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
						<li>
                          <Link to="/management_consulting">
                            <span>Management Consulting</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="widget">
                      <div className="brochure-bx">
                        <h5 className="title-head">Download</h5>
                        <Link to="#" className="download-link">
                          <img src={pdf} alt="" />
                          <h5 className="title">Download our Brochures</h5>
                          <span>Download</span>
                        </Link>
                        <Link to="#" class="download-link">
                          <img src={doc} alt="" />
                          <h5 className="title">Our Company Details</h5>
                          <span>Download</span>
                        </Link>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default Sone;
