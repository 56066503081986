import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../../layout/header2";
import Footer from "../../layout/footer";

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/services/Hospital Marketing.png";
import pdf from "../../../images/icon/pdf.png";
import doc from "../../../images/icon/doc.png";

class Ssix extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div className="banner-wraper">
            <div
              className="page-banner"
              style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>Hospital Marketing</h1>
                  <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="feather feather-home"
                          >
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                          </svg>{" "}
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Hospital Marketing
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
              <img className="pt-img2 animate2" src={circleDots} alt="" />
              <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
            </div>
          </div>

          <section className="section-area section-sp1">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mb-30">
                  <div className="ttr-media mb-30">
                    <img src={servicesPic1} className="rounded" alt="" />
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <h2 className="title mb-15">Hospital Marketing</h2>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        Marketing for the healthcare industry are now essential.
                        To stand out from the voluminous competition and win
                        over web-savvy potential clients who have access to a
                        wide range of information and a number of alternatives
                        with a single click, you must adamantly state and
                        proclaim that your healthcare facility is the greatest.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        Patients no longer rely only on recommendations from
                        family doctors or word-of-mouth recommendations. Before
                        choosing a hospital, today's patients research the
                        reputation, social media presence, reviews of the
                        doctors, facilities and services provided, etc. Simply
                        being outstanding is not enough to attract customers;
                        you also need to articulate and communicate your
                        excellence.
                      </p>
                    </div>

                    <div className="head-text mb-30">
                      <h4 className="title mb-10">
                        The following elements will be thoroughly examined
                        before your marketing plan is finalised
                      </h4>
                    </div>
                    <div className="head-text mb-30">
                      <p
                        className="mb-0"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        Core USP
                      </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        The branding narrative for your hospital may be built
                        around a USP, which is a distinct feature or exceptional
                        quality. Your sophisticated diagnostic methods or your
                        first-rate infrastructure could be to blame. Discover
                        your USP by delving deeply into your core competencies
                        and brainstorming with your team. Then, utilise it to
                        brand your hospital or healthcare institution and
                        implement innovative marketing strategies to promote it.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p
                        className="mb-0"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        Patient is prioritised
                      </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        You need to make an effort to enter the heads of your
                        potential customers. Through surveys, feedback forms,
                        and questionnaires, learn about their needs and
                        expectations, and then design your strategy to meet
                        their wants and expectations. Your hospital branding
                        effort must develop around the preferences,
                        recommendations, and concerns of your patients.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p
                        className="mb-0"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        Analysis of the Market & Competition
                      </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        Being aware of market trends and your rivals' activity
                        is important since you may utilise this information in
                        your marketing strategy. Your marketing strategy should
                        be flexible and adaptable to any shifting paradigms
                        given the market's inherent unpredictability.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p
                        className="mb-0"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        Prioritise improving patient experience
                      </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        The patient should have a hassle-free experience
                        throughout the whole process, from making an appointment
                        to being discharged, receiving follow-up care, and
                        settling insurance. The number one priority that should
                        permeate every employee in your company is customer
                        happiness. Keep in mind the 5 Cs. Complete care that is
                        compassionate, devoted, knowledgeable, and reliable will
                        ensure a lasting relationship. These grateful patients
                        will serve as your brand ambassadors and spread the word
                        about your institution to their contacts.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p
                        className="mb-0"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        Presence in social media
                      </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        Because most of your potential patients could be on
                        social media platforms like Facebook, Instagram,
                        Twitter, and LinkedIn, your hospital should have a
                        robust social media presence that is consistent with all
                        aspects of your marketing plan. We are aware of your
                        goal, which is to change lives. With the aid of a solid
                        marketing and branding strategy, you may better
                        accomplish this by connecting with your target
                        consumers. We are a devoted group of hospital management
                        specialists that can give you personalised marketing
                        strategy suggestions after thorough research.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <aside className="sticky-top pb-1">
                    <div className="widget">
                      <ul className="service-menu">
                        <li>
                          <Link to="/healthcare_quality_and_accreditations">
                            <span>Healthcare Quality & Accreditations</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/medical_tourism_in_india">
                            <span>Medical Tourism In India</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/hospital_planning">
                            <span>Hospital Planning</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/biomedical_equipment_planning_and_procurement">
                            <span>
                              Biomedical Equipment Planning and Procurement
                            </span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/human_resource_planning">
                            <span>Human Resource Planning</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li className="active">
                          <Link to="/hospital_marketing">
                            <span>Hospital Marketing</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/corporate_and_tpa_business_tie_ups">
                            <span>Corporate and TPA Business Tie-Ups</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/insurance_services">
                            <span>Insurance Services</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/hospital_licenses">
                            <span>Hospital Licenses</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/management_consulting">
                            <span>Management Consulting</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="widget">
                      <div className="brochure-bx">
                        <h5 className="title-head">Download</h5>
                        <Link to="#" className="download-link">
                          <img src={pdf} alt="" />
                          <h5 className="title">Download our Brochures</h5>
                          <span>Download</span>
                        </Link>
                        <Link to="#" class="download-link">
                          <img src={doc} alt="" />
                          <h5 className="title">Our Company Details</h5>
                          <span>Download</span>
                        </Link>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default Ssix;
