import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

// Elements
import BackToTop from './elements/back-top';
import PageScrollTop from './elements/page-scroll-top';

// All Pages Router
import Index from './pages/index';
import AboutUs from './pages/about-us';
import Team from './pages/team';
import Services from './pages/services';
import ServiceDetail from './pages/service-detail';
import Sone from './pages/service/Sone';
import Sthree from './pages/service/Sthree';

import FormLogin from './pages/form-login';
import FormRegister from './pages/form-register';
import FormForgetPassword from './pages/form-forget-password';
import Faq from './pages/faq';
import ContactUs from './pages/contact-us';
import Booking from './pages/booking';
import NewsAndUpdates from './pages/blog-grid';
// import BlogDetails from './pages/blog-details';
import Error from './pages/error-404';
import Sfore from './pages/service/Sfour';
import Stwo from './pages/service/Stwo';
import Sfive from './pages/service/Sfive';
import Ssix from './pages/service/Ssix';
import Sseven from './pages/service/Sseven';
import Seight from './pages/service/Seight';
import Sten from './pages/service/Sten';
import Snine from './pages/service/Snine';

class Markup extends Component{
	render(){
		return(
			<>
				<BrowserRouter>
				
					<Switch>
					
						<Route path='/' exact component={Index} />
						<Route path='/about-us' exact component={AboutUs} />
						<Route path='/team' exact component={Team} />
						<Route path='/services' exact component={Services} />
						<Route path='/healthcare_quality_and_accreditations' exact component={Sone} />
						<Route path='/medical_tourism_in_india' exact component={Stwo} />
						<Route path='/hospital_planning' exact component={Sthree} />
						<Route path='/biomedical_equipment_planning_and_procurement' exact component={Sfore} />
						<Route path='/human_resource_planning' exact component={Sfive} />	
						<Route path='/hospital_marketing' exact component={Ssix} />		
						<Route path='/corporate_and_tpa_business_tie_ups' exact component={Sseven} />		
						<Route path='/insurance_services' exact component={Seight} />		
						<Route path='/hospital_marketing' exact component={Snine} />		
						<Route path='/management_consulting' exact component={Sten} />						
						{/* <Route path='/service-detail' exact component={ServiceDetail} /> */}
						<Route path='/form-login' exact component={FormLogin} />
						<Route path='/form-register' exact component={FormRegister} />
						<Route path='/form-forget-password' exact component={FormForgetPassword} />
						<Route path='/faq' exact component={Faq} />
						<Route path='/contact-us' exact component={ContactUs} />
						<Route path='/booking' exact component={Booking} />
						<Route path='/newsandupdates' exact component={NewsAndUpdates} />
						<Route path='/blog-details' exact component={NewsAndUpdates} />
						<Route component={Error} />
						
					</Switch>
					
					<PageScrollTop />
					
				</BrowserRouter>
				
				<BackToTop />
				
			</>
		);
	}
}

export default Markup;