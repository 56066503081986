import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../../layout/header2";
import Footer from "../../layout/footer";

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/services/Biomedical Equipment Planning and Procurement.png";
import pdf from "../../../images/icon/pdf.png";
import doc from "../../../images/icon/doc.png";

class Sfore extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div className="banner-wraper">
            <div
              className="page-banner"
              style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>Biomedical Equipment Planning and Procurement</h1>
                  <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="feather feather-home"
                          >
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                          </svg>{" "}
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Biomedical Equipment Planning and Procurement
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
              <img className="pt-img2 animate2" src={circleDots} alt="" />
              <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
            </div>
          </div>

          <section className="section-area section-sp1">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mb-30">
                  <div className="ttr-media mb-30">
                    <img src={servicesPic1} className="rounded" alt="" />
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <h2 className="title mb-15">
                        Biomedical Equipment Planning and Procurement
                      </h2>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        Planning for medical equipment is a crucial step in
                        every healthcare facility's project. Actually, it is
                        essential to the Project's overall success. The
                        procurement of equipment accounts for more than 35% of
                        the total project costs, making careful planning of this
                        activity essential to the project's overall viability.
                        Even a 10% change in the prices or delivery times of
                        medical equipment can push back the hospital's breakeven
                        point by two to three years, significantly affecting the
                        venture's potential to make money.
                      </p>
                    </div>

                    <div className="head-text mb-30">
                      <p
                        className="mb-0"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        Planning for medical equipment requires a specialised
                        team approach
                      </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        In today's significant healthcare projects, medical
                        equipment planning is handled by either specialised
                        in-house teams or medical equipment consultants, i.e.,
                        outside experts who start working on the project from
                        the idea stage. This activity may be best completed by a
                        multi-skilled team of professionals from each of these
                        fields, as we have, because it is quite complicated and
                        calls for not only a thorough understanding of the
                        therapeutic demands but also a thorough knowledge of
                        budgeting, architectural design, and building process.
                        Need assessments, budgeting and planning, purchasing,
                        and installation all need to be done in a way that is
                        specifically tailored to your hospital's services,
                        operational objectives, and financial constraints.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        The Equipment Planning Team's task is to always stay up
                        to date on the most recent advancements in the industry
                        because technology is ever-evolving. For this to be
                        accomplished successfully, the Medical Equipment
                        Consultants need a strong team working together, with
                        members having the highest levels of skills and
                        expertise in installation techniques, all statutory and
                        regulatory design and layout standards, and environment
                        conditions under which the equipment will be operating
                        properly. Additionally, a team approach guarantees that
                        all of these specifications and design difficulties are
                        properly addressed while also taking into consideration
                        the challenging logistics and the need to lower total
                        costs.
                      </p>
                    </div>

                    <div className="head-text mb-30">
                      <h4>
                        Important elements in the planning of medical equipment
                      </h4>
                      <div className="col-md-12 mb-30">
                        <ul className="list-check-squer mb-0">
                          <li>Conceptualization</li>
                          <li>Designing Criteria</li>
                          <li>Setting Up Specifications</li>
                          <li>Budgeting</li>
                          <li>Scheduling & Planning</li>
                          <li>
                            Selection and procurement, including negotiation,
                            finalisation, and tendering
                          </li>
                          <li>Putting in Place & Commissioning</li>
                        </ul>
                      </div>
                    </div>
                    <div className="head-text mb-30">
                      <p
                        className="mb-0"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        The effect of medical technology on hospital viability
                      </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        Our team of medical equipment planners and procurement
                        specialists collaborate closely with your clinicians,
                        feasibility experts, engineers, architects, and MEP
                        consultants to first comprehend the hospital project's
                        stage-by-stage rollout plan and the services required.
                        We then ensure that the equipment being procured at each
                        stage is finished and seamlessly integrated into the
                        hospital's operations as it expands. This way, as your
                        medical equipment consultants, we can guarantee that all
                        of the equipment is used to its maximum potential in
                        order to provide the patient with the high- quality
                        treatment that has been committed to, in a time- and
                        money-saving manner.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        Before a final purchase choice is made, each of the
                        available equipment options is thoroughly evaluated for
                        techno-commercial viability during the procurement stage
                        itself. In addition to having an adverse effect on
                        viability, every additional rupee spent on equipment
                        eventually raises the cost of medical services offered
                        by the hospital. In the pursuit of new technology for
                        its own sake, this is frequently neglected. The Medical
                        Equipment Consultant Team must conduct the evaluation in
                        a professional and unbiased manner.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p
                        className="mb-0"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        Medical equipment needs to be finalised throughout the
                        hospital design and planning stage
                      </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        Early on in the process of creating the hospital's
                        layout, having a clear understanding of the medical
                        equipment being purchased, including its kind, make, and
                        specifications, is helpful. So, the building project
                        proceeds efficiently and on schedule. When actual
                        equipment sizes are discovered to be significantly
                        different from those originally anticipated by the
                        hospital's architect and planner, significant project
                        delays and disruptions result. The kind and brand of the
                        medical equipment dictates everything from the floor
                        plans and ceiling heights to the placement and thickness
                        of the walls.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p
                        className="mb-0"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        Respecting all Statutory & Regulatory Requirements as
                        well as NABH / JCI Accreditation
                      </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        Today, complete conformity with the standards of
                        national and international quality accreditations like
                        the NABH & JCI is a must for all newly constructed
                        hospitals. If a hospital is designed primarily to serve
                        medical tourists from other countries, JCI accreditation
                        becomes a necessity, but all government and private
                        insurers in India have made NABH accreditation a
                        condition for all patients.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        The critical care areas, including as operating rooms,
                        CSSDs, emergency rooms, ICUs, and ICCUs, are where the
                        majority of equipment categories need to be made
                        compliant with accreditation standards. Following the
                        Equipment Specifications and Performance Criteria is
                        required by the Accreditation Standards. The Standards
                        also demand that the National Building Code (NBC) and
                        Atomic Energy Regulatory Board's statutory and
                        regulatory requirements be followed (AERB). It would be
                        quite risky to not adhere to standards during the
                        medical equipment planning and procurement stage.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p
                        className="mb-0"
                        style={{ fontWeight: "bold", color: "black" }}
                      >
                        Coordinating the Delivery, Erection, and Commissioning
                        of Hospital Equipment
                      </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                        Before a typical multi-specialty hospital can start
                        operating, more than 1,000 different pieces of medical
                        equipment and accessories must be decided upon,
                        purchased, delivered, built, and commissioned. Based on
                        their size, price, delivery, erection, and commissioning
                        times, these are all ordered and purchased at various
                        phases of the project. This entire procedure is
                        incredibly logistically complex, and any delay in one of
                        these causes a delay in commissioning the hospital and
                        receiving payments. Project delays result in cost
                        overruns and a delay in repayments because all
                        significant hospital projects today are funded by banks
                        or other corporate institutions. Therefore, the Medical
                        Equipment Planning Team, along with the Architects and
                        Planners, has to be in place from the project's
                        conceptual stage onward.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <aside className="sticky-top pb-1">
                    <div className="widget">
                      <ul className="service-menu">
                        <li>
                          <Link to="/healthcare_quality_and_accreditations">
                            <span>Healthcare Quality & Accreditations</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/medical_tourism_in_india">
                            <span>Medical Tourism In India</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/hospital_planning">
                            <span>Hospital Planning</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li className="active">
                          <Link to="/biomedical_equipment_planning_and_procurement">
                            <span>
                              Biomedical Equipment Planning and Procurement
                            </span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/human_resource_planning">
                            <span>Human Resource Planning</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/hospital_marketing">
                            <span>Hospital Marketing</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/corporate_and_tpa_business_tie_ups">
                            <span>Corporate and TPA Business Tie-Ups</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/insurance_services">
                            <span>Insurance Services</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/hospital_licenses">
                            <span>Hospital Licenses</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/management_consulting">
                            <span>Management Consulting</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="widget">
                      <div className="brochure-bx">
                        <h5 className="title-head">Download</h5>
                        <Link to="#" className="download-link">
                          <img src={pdf} alt="" />
                          <h5 className="title">Download our Brochures</h5>
                          <span>Download</span>
                        </Link>
                        <Link to="#" class="download-link">
                          <img src={doc} alt="" />
                          <h5 className="title">Our Company Details</h5>
                          <span>Download</span>
                        </Link>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default Sfore;
