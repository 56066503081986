import React, { Component } from "react";
import { Link } from "react-router-dom";


// Layout
import Header from "../../layout/header2";
import Footer from "../../layout/footer";

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/services/Hospital Planning.png";
import pdf from "../../../images/icon/pdf.png";
import doc from "../../../images/icon/doc.png";

class Sthree extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div className="banner-wraper">
            <div
              className="page-banner"
              style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>Hospital Planning</h1>
                  <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="feather feather-home"
                          >
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                          </svg>{" "}
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page">
                        Hospital Planning
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
              <img className="pt-img2 animate2" src={circleDots} alt="" />
              <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
            </div>
          </div>

          <section className="section-area section-sp1">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mb-30">
                  <div className="ttr-media mb-30">
                    <img src={servicesPic1} className="rounded" alt="" />
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <h2 className="title mb-15">
                      Hospital Planning
                      </h2>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      A relatively specialised sector, planning the design and construction of healthcare facilities, has only just started to gain recognition in India. This is because we've come to understand how crucially vital the hospital's architecture and planning are to the patient's recovery. The goal of an architect is to make sure that the people using and residing in the area they build are satisfied with the design. This covers the patients as well as the doctors, nurses, staff, and other personnel at a hospital. A hospital's layout must adhere to all of its specifications. Therefore, it is the Architect's obligation and duty to design appropriately.                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      Our healthcare design is driven by patient care. All of our architects, planners, quality consultants, and project managers work toward ensuring their safety and receiving the best care possible.
                      </p>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{fontWeight: 'bold', color: 'black'}}>How Hospital Design has changed as a result of accreditation</p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      With the introduction of healthcare accreditations like the NABH & JCI and consultants like us who specialise in the industry, the situation started to alter. We were able to comprehend the need for particular infrastructure to fulfil the NABH and JCI criteria thanks to our extensive expertise in the field of quality accreditation. We also realised that it was essential to consider these factors from the planning and layout stage for healthcare facilities.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      We observed that nearly every area of the hospital needed to be planned with an eye toward complying with the Patient Safety requirements of the Accreditation Standards, starting with designing the widths of the staircases and corridors, planning the layouts of areas like the Operation Theatres and Critical Care Units, providing the necessary space between Beds in the Wards, even locating the Nursing Stations, etc.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{fontWeight: 'bold', color: 'black'}}>Every hospital planner and architect is accountable for improving patient outcomes</p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      By stopping the spread of infections and enabling quicker patient discharge, it is our responsibility as hospital architects and designers to contribute to better patient outcomes. Through improved planning and facility layout that ensures better patient and staff flow and regulated air circulation, we need to make sure that there is effective infection control in our hospitals.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      As hospital planners and designers, we work to make all sections of the hospital accessible and patient friendly, which is another crucial feature that contributes to better results.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    <h4 className="title mb-10">Important Components of Hospital Design</h4>
                    <p className="mb-0" style={{fontWeight: 'bold', color: 'black'}}>Turning your ideas into reality</p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      Our architect's first step in turning your idea into reality is the concept design. This depends on how creatively the architect interprets your brief and interacts with you until you fully grasp it. At this point, we also do in-depth research, visit sites, and regularly communicate with the client and various user groups.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    
                    <p className="mb-0" style={{fontWeight: 'bold', color: 'black'}}>Turning your ideas into reality</p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      Our architect's first step in turning your idea into reality is the concept design. This depends on how creatively the architect interprets your brief and interacts with you until you fully grasp it. At this point, we also do in-depth research, visit sites, and regularly communicate with the client and various user groups.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    
                    <p className="mb-0" style={{fontWeight: 'bold', color: 'black'}}>Feasibility Study is required</p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      A feasibility study, whose goal is to comprehend the healthcare needs in the hospital's target location, should preferably be conducted before the hospital planning and architecture design process. This enables us to complete the services, including the specialties, and to have a good indication of the anticipated patient load. All of these criteria should be fully taken into account in the hospital concept design.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{fontWeight: 'bold', color: 'black'}}>Conceptualisation</p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      The Concept Design includes the elevation of the Hospital Structure, block connections of the departments that illustrate space, form, and position inside the building, as well as vertical circulation features like elevators and staircases and the arrangement of the horizontal circulation pathways (corridors).
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{fontWeight: 'bold', color: 'black'}}>The maintenance of operation and adherence to laws and regulations</p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      When designing hospitals, we also consider how well the facility will work within the surrounding built-up region, as well as any applicable local laws and planning regulations, in order to fully comply with national and/or international hospital quality accreditations like the NABH & JCI.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                      <h4>
                      Services for Detailed Hospital Architecture
                      </h4>
                      <div className="col-md-12 mb-30">
                        <ul className="list-check-squer mb-0">
                          <li>The hospital's and the civil component's whole designs</li>
                          <li>The development of the complex's overall master plan</li>
                          <li>Constructing a hospital's design</li>
                          <li>Interior Designing: Furniture, Facade, 2D and 3D Views, Material Chart</li>
                          <li>Plannng of facilities and internal space</li>
                          <li>Extraneous support services</li>
                          <li>Blocks of architecture</li>
                          <li>Hospital department room layouts, including their sizes</li>
                          <li>Schematic images of the planned structure showing its plans, elevations, and sections</li>
                          <li>Programmed Spacecraft</li>
                          <li>Quantity Bills (BOQs)</li>
                          <li>Design review for new hospitals and nursing homes in accordance with the necessary NBC, NABH, Fire, national and international clinical guidelines - compliances, and local bylaws</li>
                        </ul>
                      </div>
                    </div>
                    <div className="head-text mb-30">
                      <h4>
                      Services for Detailed Hospital Architecture
                      </h4>
                      <div className="col-md-12 mb-30">
                        <ul className="list-check-squer mb-0">
                          <li>The hospital's and the civil component's whole designs</li>
                          <li>The development of the complex's overall master plan</li>
                          <li>Constructing a hospital's design</li>
                          <li>Interior Designing: Furniture, Facade, 2D and 3D Views, Material Chart</li>
                          <li>Plannng of facilities and internal space</li>
                          <li>Extraneous support services</li>
                          <li>Blocks of architecture</li>
                          <li>Hospital department room layouts, including their sizes</li>
                          <li>Schematic images of the planned structure showing its plans, elevations, and sections</li>
                          <li>Programmed Spacecraft</li>
                          <li>Quantity Bills (BOQs)</li>
                          <li>Design review for new hospitals and nursing homes in accordance with the necessary NBC, NABH, Fire, national and international clinical guidelines - compliances, and local bylaws</li>
                        </ul>
                      </div>
                    </div>
                    <div className="head-text mb-30">
                      <h4>
                      Architectural Services for Hospitals
                      </h4>
                      <div className="col-md-12 mb-30">
                        <ul className="list-check-squer mb-0">
                          <li>Complete structural framework design</li>
                          <li>Suitable framing system</li>
                          <li>For the on-site work, precise construction plans are needed.</li>
                        </ul>
                      </div>
                    </div>
                    <div className="head-text mb-30">
                      <h4>
                      Services for Detailed Hospital Architecture
                      </h4>
                      <div className="col-md-12 mb-30">
                        <ul className="list-check-squer mb-0">
                        <li>Services Design (MEP)</li>
                          <li>Sanitary</li>
                          <li>Plumbing</li>
                          <li>Water Sources</li>
                          <li>Design of Firefighting and Protection Systems</li>
                          <li>Specifications for elevators</li>
                          <li>System for Telecommunication</li>
                          <li>Systems for public address and video surveillance</li>
                          <li>Pipeline for medical gas (MGPS) Voice and Design Data Networking</li>
                          <li>Management Systems for Buildings</li>
                          <li>Systems for sewage treatment and disposal</li>
                          <li>collecting rainwater for the complex</li>
                        </ul>
                      </div>
                    </div>
                    <div className="head-text mb-30">
                      <h4>
                      Services for Detailed Hospital Architecture
                      </h4>
                      <div className="col-md-12 mb-30">
                        <ul className="list-check-squer mb-0">
                          <li>The hospital's and the civil component's whole designs</li>
                          <li>The development of the complex's overall master plan</li>
                          <li>Constructing a hospital's design</li>
                          <li>Interior Designing: Furniture, Facade, 2D and 3D Views, Material Chart</li>
                          <li>Plannng of facilities and internal space</li>
                          <li>Extraneous support services</li>
                          <li>Blocks of architecture</li>
                          <li>Hospital department room layouts, including their sizes</li>
                          <li>Schematic images of the planned structure showing its plans, elevations, and sections</li>
                          <li>Programmed Spacecraft</li>
                          <li>Quantity Bills (BOQs)</li>
                          <li>Design review for new hospitals and nursing homes in accordance with the necessary NBC, NABH, Fire, national and international clinical guidelines - compliances, and local bylaws</li>
                        </ul>
                      </div>
                    </div>
                    <div className="head-text mb-30">
                      <h4>
                      Crucial design areas
                      </h4>
                      <div className="col-md-12 mb-30">
                        <ul className="list-check-squer mb-0">
                          <li>OT & CSSD Complex</li>
                          <li>ICUs</li>
                          <li>Emergency</li>
                          <li>Accident & Trauma</li>
                          <li>Radiology & Radiotherapy</li>
                          <li>Nuclear Medicine designs</li>
                        </ul>
                      </div>
                    </div>
                    <div className="head-text mb-30">
                      <h4>
                      Extra Designs
                      </h4>
                      <div className="col-md-12 mb-30">
                        <ul className="list-check-squer mb-0">
                          <li>Exteriors - Landscape Architecture</li>
                          <li>Kitchen and laundry room layout and design</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <aside className="sticky-top pb-1">
                    <div className="widget">
                    <ul className="service-menu">
                        <li>
                          <Link to="/healthcare_quality_and_accreditations">
                            <span>Healthcare Quality & Accreditations</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
						<li>
                          <Link to="/medical_tourism_in_india">
                            <span>Medical Tourism In India</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li  className="active">
                          <Link to="/hospital_planning">
                            <span>Hospital Planning</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/biomedical_equipment_planning_and_procurement">
                            <span>Biomedical Equipment Planning and Procurement</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/human_resource_planning">
                            <span>Human Resource Planning</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/hospital_marketing">
                            <span>Hospital Marketing</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/corporate_and_tpa_business_tie_ups">
                            <span>Corporate and TPA Business Tie-Ups</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
						<li>
                          <Link to="/insurance_services">
                            <span>Insurance Services</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/hospital_licenses">
                            <span>Hospital Licenses</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
						<li>
                          <Link to="/management_consulting">
                            <span>Management Consulting</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="widget">
                      <div className="brochure-bx">
                        <h5 className="title-head">Download</h5>
                        <Link to="#" className="download-link">
                          <img src={pdf} alt="" />
                          <h5 className="title">Download our Brochures</h5>
                          <span>Download</span>
                        </Link>
                        <Link to="#" class="download-link">
                          <img src={doc} alt="" />
                          <h5 className="title">Our Company Details</h5>
                          <span>Download</span>
                        </Link>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default Sthree;
