import React, { Component } from "react";
import { Link } from "react-router-dom";


// Layout
import Header from "../../layout/header2";
import Footer from "../../layout/footer";

// Import Images
import bnrImg1 from "../../../images/banner/img1.jpg";
import waveBlue from "../../../images/shap/wave-blue.png";
import circleDots from "../../../images/shap/circle-dots.png";
import plusBlue from "../../../images/shap/plus-blue.png";
import servicesPic1 from "../../../images/services/Hospital Licenses.png";
import pdf from "../../../images/icon/pdf.png";
import doc from "../../../images/icon/doc.png";

class Snine extends Component {
  render() {
    return (
      <>
        <Header />

        <div className="page-content bg-white">
          <div className="banner-wraper">
            <div
              className="page-banner"
              style={{ backgroundImage: "url(" + bnrImg1 + ")" }}
            >
              <div className="container">
                <div className="page-banner-entry text-center">
                  <h1>Hospital Licenses</h1>
                  <nav aria-label="breadcrumb" className="breadcrumb-row">
                    <ul className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="feather feather-home"
                          >
                            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                            <polyline points="9 22 9 12 15 12 15 22"></polyline>
                          </svg>{" "}
                          Home
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Hospital Licenses
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <img className="pt-img1 animate-wave" src={waveBlue} alt="" />
              <img className="pt-img2 animate2" src={circleDots} alt="" />
              <img className="pt-img3 animate-rotate" src={plusBlue} alt="" />
            </div>
          </div>

          <section className="section-area section-sp1">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mb-30">
                  <div className="ttr-media mb-30">
                    <img src={servicesPic1} className="rounded" alt="" />
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <h2 className="title mb-15">
                      Hospital Licenses
                      </h2>                      
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      Medical licencing is the official declaration that a healthcare facility is equipped with the necessary infrastructure and knowledge to function. Any healthcare institution that provides services must be licenced, and each state has its own set of standards that must be adhered to by the facility. Legal action may be taken if any physician connected to a healthcare system is operating without an up- to-date licence.
                      </p>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="head-text mb-30">
                      <h4 className="title mb-10">Healthcare Licensing Common Regulatory Documents</h4>
                    </div>
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{ fontWeight: "bold", color: "black" }}>
                    Permit for land or space</p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      You must obtain approval from the local authority to utilise the site for a hospital building even before the first brick is set. A land permit is what is used here. The governing authority inspects the site, the development plan, and the potential environmental effect before issuing the land permission.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{ fontWeight: "bold", color: "black" }}>
                    NABH and JCI</p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      Every hospital and healthcare institution must adhere to the standards of the NABH (National Accreditation Board for Healthcare) and JCI, two national and international quality accreditations. All Insurers have mandated NABH, which assures patient safety and high-quality services.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{ fontWeight: "bold", color: "black" }}>
                    PCPNDT</p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      Because of sex selection throughout the prenatal periods, female infanticide has become epidemic in India. The Pre Conception and Prenatal Diagnostic Techniques (Prohibition of Sex Selection) Act, which forbids gender selection for foetuses, was passed to put an end to this immoral practise.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{ fontWeight: "bold", color: "black" }}>
                    AERB</p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      Radiation treatment, nuclear medicine, and X-ray equipment are all used by some hospitals and healthcare facilities. Consider oncology centres. These hospitals must submit an application for an AERB certificate, which will evaluate and confirm that the radiation being used is safe for both human health and the environment.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{ fontWeight: "bold", color: "black" }}>
                    NBC</p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      A legal requirement that must be met prior to construction is the National Building Code.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{ fontWeight: "bold", color: "black" }}>
                    MTP</p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      This licence governs medical pregnancy termination and is particularly necessary in gynaecological and nursing care settings.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{ fontWeight: "bold", color: "black" }}>
                    Drug sales permit </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      The hospital will need a medicine selling licence if it has a pharmacy or chemist store on its property.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{ fontWeight: "bold", color: "black" }}>
                    NOC</p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      If you are beginning your medical practise within a society, you must obtain the NOC from the society governing committee.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{ fontWeight: "bold", color: "black" }}>
                    Certificate of fire safety</p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      Fire is a danger that may ruin both people and property. The fire safety certificate examines and certifies the fire safety procedure at your hospital. These days, even hospitals and modest nursing homes must need this licence.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{ fontWeight: "bold", color: "black" }}>
                    Permits for water and electricity</p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      The amount of water and power needed by a hospital is enormous. It is necessary to get water and power permits from the local authority and the electrical board.
                      </p>
                    </div>
                    <div className="head-text mb-30">
                    <p className="mb-0" style={{ fontWeight: "bold", color: "black" }}>
                    Certification for biomedical waste </p>
                      <p className="mb-0" style={{ textAlign: "justify" }}>
                      Biomedical waste is produced in abundance by hospitals and healthcare facilities. These wastes must be properly disposed of in order to avoid contaminating the environment. To control this, your hospital must get a certificate for biomedical waste from the government's sanitation department.
                      </p>
                    </div>
                    
                    
                  </div>
                </div>
                <div className="col-lg-4">
                  <aside className="sticky-top pb-1">
                    <div className="widget">
                    <ul className="service-menu">
                        <li>
                          <Link to="/healthcare_quality_and_accreditations">
                            <span>Healthcare Quality & Accreditations</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
						<li>
                          <Link to="/medical_tourism_in_india">
                            <span>Medical Tourism In India</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/hospital_planning">
                            <span>Hospital Planning</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/biomedical_equipment_planning_and_procurement">
                            <span>Biomedical Equipment Planning and Procurement</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/human_resource_planning">
                            <span>Human Resource Planning</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/hospital_marketing">
                            <span>Hospital Marketing</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="/corporate_and_tpa_business_tie_ups">
                            <span>Corporate and TPA Business Tie-Ups</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
						<li>
                          <Link to="/insurance_services">
                            <span>Insurance Services</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                        <li  className="active">
                          <Link to="/hospital_licenses">
                            <span>Hospital Licenses</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
						<li>
                          <Link to="/management_consulting">
                            <span>Management Consulting</span>
                            <i className="fa fa-angle-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="widget">
                      <div className="brochure-bx">
                        <h5 className="title-head">Download</h5>
                        <Link to="#" className="download-link">
                          <img src={pdf} alt="" />
                          <h5 className="title">Download our Brochures</h5>
                          <span>Download</span>
                        </Link>
                        <Link to="#" class="download-link">
                          <img src={doc} alt="" />
                          <h5 className="title">Our Company Details</h5>
                          <span>Download</span>
                        </Link>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>
        </div>

        <Footer />
      </>
    );
  }
}

export default Snine;
